import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SuperChatLoader = ({ isAuthenticated }) => {
  const location = useLocation();

  useEffect(() => {
    // Rutele unde scriptul NU trebuie încărcat
    const excludedRoutes = [
      '/client/basic',
      '/client/standard',
      '/client/premium',
      '/client/ultimate',
    ];

    // Verifică dacă ruta curentă începe cu una dintre cele excluse
    const isExcludedRoute = excludedRoutes.some((route) =>
      location.pathname.startsWith(route)
    );

    const existingScript = document.getElementById('superchat-script');

    // Elimină scriptul dacă utilizatorul este autentificat și pe o pagină exclusă
    if (isExcludedRoute || isAuthenticated) {
      if (existingScript) {
        existingScript.remove();
        if (window.Superchat && window.Superchat.destroy) {
          window.Superchat.destroy();
        }
        console.log('SuperChat script removed due to exclusion or authentication');
      }
      return; // Nu încărcăm scriptul
    }

    // Încarcă scriptul SuperChat dacă nu este exclus
    if (!existingScript) {
      const script = document.createElement('script');
      script.id = 'superchat-script';
      script.src = 'https://widget.superchat.de/snippet.js?applicationKey=WClo1RPmY7wBYj1ardeMpGQDL3';
      script.async = true;
      script.onload = () => {
        if (window.Superchat) {
          window.Superchat.init({
            applicationKey: 'WClo1RPmY7wBYj1ardeMpGQDL3',
          });
          console.log('SuperChat initialized successfully');
        }
      };
      document.body.appendChild(script);
    }

    // Curățare la schimbarea rutei
    return () => {
      if (existingScript) {
        existingScript.remove();
        if (window.Superchat && window.Superchat.destroy) {
          window.Superchat.destroy();
        }
        console.log('SuperChat script removed on route change');
      }
    };
  }, [location, isAuthenticated]);

  return null; // Componenta nu afișează nimic
};

export default SuperChatLoader;
