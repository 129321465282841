import React, { useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import PricingPage from './components/pages/PricingPage';
import RegisterPage from './components/pages/RegisterPage';
import ForgetPasswordPage from './components/pages/ForgetPasswordPage';
import BasicPage from './components/pages/client/BasicPage';
import StandartPage from './components/pages/client/StandartPage';
import PremiumPage from './components/pages/client/PremiumPage';
import UltimatePage from './components/pages/client/UltimatePage';
import ConfirmationPage from './components/pages/ConfirmationPage';
import ShareTrucking from './components/pages/client/ShareTrucking';
import FaqPage from './components/pages/faq';
import ResetPasswordPage from './components/pages/ResetPasswordPage';
import PrivateRoute from './components/PrivateRoute';
import AboutUsPage from './components/pages/aboutus';
import ContactPage from './components/pages/ContactPage';
import SuperChatLoader from './components/shared/SuperChatLoader';

const App = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Starea autentificării

  // Rutele unde scriptul SuperChat nu trebuie încărcat
  const excludedRoutes = [
    '/client/basic',
    '/client/standart',
    '/client/premium',
    '/client/ultimate',
  ];

  // Verificăm dacă ruta curentă este exclusă
  const isExcludedRoute = excludedRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <>
      {/* Încarcă SuperChat doar pe paginile care nu sunt excluse */}
      <SuperChatLoader isAuthenticated={isAuthenticated} />

      <Routes>
        {/* Rute publice */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={<LoginPage onLogin={() => setIsAuthenticated(true)} />}
        />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/trucking/:driverId/:expires" element={<ShareTrucking />} />
        <Route path="/FaqPage" element={<FaqPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactPage />} />

        {/* Rute protejate pentru paginile clienților */}
        <Route element={<PrivateRoute />}>
          <Route path="/client/basic/*" element={<BasicPage />} />
          <Route path="/client/standart/*" element={<StandartPage />} />
          <Route path="/client/premium/*" element={<PremiumPage />} />
          <Route path="/client/ultimate/*" element={<UltimatePage />} />
       
        </Route>
      </Routes>
    </>
  );
};

export default App;
