import React, { useState, useEffect } from 'react';
import styles from './NotificationsSettings.module.css';

const Notifications = ({ show, message, onClick, intervalDays = 7 }) => {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    if (!show) return;

    setIsVisible(true);

    const hideTimeout = setTimeout(() => {
      setIsVisible(false);
    }, 5000); // Ascunde notificarea după 5 secunde

    return () => clearTimeout(hideTimeout);
  }, [show]);

  // Notificare periodică la fiecare intervalDays zile
  useEffect(() => {
    if (!show) return;

    const interval = setInterval(() => {
      setIsVisible(true);

      const hideTimeout = setTimeout(() => {
        setIsVisible(false);
      }, 5000);

      return () => clearTimeout(hideTimeout);
    }, intervalDays * 24 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, [show, intervalDays]);

  if (!isVisible) return null;

  return (
    <div className={styles.notificationContainer} onClick={onClick}>
      <div className={styles.notificationContent}>
        <p>{message}</p>
        <button
          className={styles.notificationcloseButton}
          onClick={(e) => {
            e.stopPropagation();
            setIsVisible(false);
          }}
        >
          ✕
        </button>
      </div>
    </div>
  );
};

export default Notifications;
