import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/LoginPage.module.css';
import videoBackground from '../styles/background.mp4';
axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token');
   
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isTwoFactorRequired, setIsTwoFactorRequired] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [clientId, setClientId] = useState('');
  const [role, setRole] = useState('client');

  const syncSessionStorage = () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    if (token && tokenExpiration) {
        const isTokenValid = new Date() < new Date(tokenExpiration);
        if (isTokenValid) {
            sessionStorage.setItem('token', token);
            console.log('Token is valid and synced to sessionStorage.');
        } else {
            console.warn('Token expired. Clearing storage.');
            localStorage.clear();
            sessionStorage.clear();
        }
    } else {
        console.warn('No token found in storage.');
    }
};

  
  
  
  useEffect(() => {
    syncSessionStorage();
  
    const token = sessionStorage.getItem('token');
    const localToken = localStorage.getItem('token');
    console.log('Verifying token in sessionStorage:', token);
    console.log('Verifying token in localStorage:', localToken);
  
    if (token) {
      const clientPackage = localStorage.getItem('clientPackage');
      const clientId = localStorage.getItem('clientId');
      if (clientPackage && clientId) {
        console.log('Token and client data found. Navigating to package...');
        navigateToPackage(clientPackage, clientId);
      } else {
        console.warn('Client package or ID is missing.');
      }
    } else {
      console.warn('No valid token found. Redirecting to login.');
    }
  }, [navigate]);
  
  
 
  
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api-backend.full-trucking-service.com/api';
      const loginEndpoint =
        role === 'client' ? '/loginClient' : role === 'dispatcher' ? '/loginDispatcher' : '/loginAccounting';

      const response = await axios.post(
        `${apiUrl}${loginEndpoint}`,
        { email, password },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.message === '2FA required') {
        setIsTwoFactorRequired(true);
        setClientId(response.data.clientId);
      } else if (response.data.message === 'Login successful') {
        const { token, clientId, package: clientPackage } = response.data;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30); // Token expiră peste 30 zile

        // Salvează token-ul și alte date necesare
        localStorage.setItem('token', token);
        localStorage.setItem('tokenExpiration', expirationDate.toISOString());
        localStorage.setItem('clientPackage', clientPackage);
        localStorage.setItem('clientId', clientId);

        sessionStorage.setItem('token', token);

        navigateToPackage(clientPackage, clientId);
      } else {
        setError('Incorrect email or password.');
      }
    } catch (err) {
      console.error('Error during login:', err);
      setError(err.response?.data?.error || 'An error occurred. Please try again.');
    }
  };
  const handleTwoFactorSubmit = async (e) => {
    e.preventDefault();
    setError('');
    console.log('[2FA] Submitting with:', { clientId, twoFactorCode });

    try {
        const apiUrl = process.env.REACT_APP_API_URL || 'https://api-backend.full-trucking-service.com/api';

        if (!clientId || !twoFactorCode) {
            setError('Client ID and 2FA code are required.');
            return;
        }

        const response = await axios.post(
            `${apiUrl}/verify-two-factor`,
            { clientId, code: twoFactorCode },
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            }
        );

        console.log('[2FA] API Response:', response.data);

        if (response.data.message === '2FA verified successfully') {
            const { token, package: clientPackage } = response.data;

            if (!token) {
                console.error('[2FA] No token received from API!');
                setError('Authentication failed. Please try again.');
                return;
            }

            console.log('[2FA] Saving token after verification:', { token, clientPackage });

            const expirationDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000); // 30 zile

            // Salvează token-ul atât în localStorage cât și în sessionStorage
            localStorage.setItem('token', token);
            localStorage.setItem('tokenExpiration', expirationDate.toISOString());
            sessionStorage.setItem('token', token);

            // Salvează alte date relevante (dacă este cazul)
            localStorage.setItem('clientPackage', clientPackage);
            localStorage.setItem('clientId', clientId);

            syncSessionStorage();

            navigateToPackage(clientPackage, clientId);
        } else {
            setError('Invalid 2FA code.');
        }
    } catch (err) {
        console.error('[2FA] Error during verification:', err.response?.data || err);
        const errorMessage = err.response?.data?.error || 'An error occurred. Please try again.';
        setError(errorMessage);
    }
};



  
  const navigateToPackage = (clientPackage, clientId) => {
    console.log('Navigating to package with:', { clientPackage, clientId });
  
    if (!clientPackage || !clientId) {
      console.error('Missing clientPackage or clientId. Cannot navigate.');
      setError('Client package or ID is missing.');
      return;
    }
  
    switch (clientPackage) {
      case 'Basic':
        navigate(`/client/basic/${clientId}`);
        break;
      case 'Standard':
        navigate(`/client/standard/${clientId}`);
        break;
      case 'Premium':
        navigate(`/client/premium/${clientId}`);
        break;
      case 'Ultimate':
        navigate(`/client/ultimate/${clientId}`);
        break;
      default:
        console.error('Invalid client package:', clientPackage);
        setError('Invalid package. Please contact support.');
    }
  };
  
  
 
  return (
    <div className={styles.loginPageContainer}>
      <video className={styles.loginPageVideoBackground} autoPlay loop muted>
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <header className={styles.loginPageheaderMenu}>
        <div onClick={() => navigate('/')} className={styles.loginPagelogo}>
          Full Trucking Service
        </div>
        <nav>
          <ul>
          <li onClick={() => navigate('/about')}>About Us</li>
            <li onClick={() => navigate('/services')}>Services</li>
            <li onClick={() => navigate('/contact')}>Contact</li>
            <li onClick={() => navigate('/register')}>Register</li>
          </ul>
        </nav>
      </header>
      <div className={styles.loginPagemainContent}>
        <div className={styles.leftContent}>
          <h1>Welcome to Full Trucking Service!</h1>
          <p>
            Your ultimate solution for dispatch, accounting, and fleet management. Designed for trucking companies
            across North and South America, we simplify your operations and reduce stress.
          </p>
          <p>Log in to access powerful tools to manage your business effortlessly.</p>
          <p>Let’s simplify trucking together!</p>
        </div>
        <div className={styles.loginSection}>
          {!isTwoFactorRequired ? (
            <form onSubmit={handleLogin} className={styles.loginForm}>
              <h2>Login</h2>
              <select value={role} onChange={(e) => setRole(e.target.value)} className={styles.loginPageinput}>
                <option value="client">Client</option>
                <option value="dispatcher">Dispatcher</option>
                <option value="accounting">Accounting</option>
              </select>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.loginPageinput1}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className={styles.loginPageinput2}
              />
              {error && <p className={styles.loginPageerror}>{error}</p>}
              <button type="submit" className={styles.loginPagebutton}>
                Login
              </button>
            </form>
          ) : (
            <form onSubmit={handleTwoFactorSubmit} className={styles.loginForm}>
            <h2>Two-Factor Authentication</h2>
            <input
              type="text"
              placeholder="Enter 2FA Code"
              value={twoFactorCode}
              onChange={(e) => setTwoFactorCode(e.target.value)}
              required
              className={styles.loginPageinput3}
            />
            {error && <p className={styles.loginPageerror}>{error}</p>}
            <button type="submit" className={styles.loginPagebutton}>
              Verify
            </button>
          </form>
          
          )}
          <div className={styles.links}>
            <button onClick={() => navigate('/pricing')} className={styles.loginPagelink}>
              Register
            </button>
            <button onClick={() => navigate('/forget-password')} className={styles.loginPagelink}>
              Forgot Password?
            </button>
          </div>
        </div>
      </div>
   
 
 <footer className={styles.loginPageFooter}>
 <p onClick={() => navigate('/terms')} className={styles.footerLink}>
   Terms of Service
 </p>
 <p onClick={() => navigate('/privacy')} className={styles.footerLink}>
   Privacy Policy
 </p>
 <p onClick={() => navigate('/help')} className={styles.footerLink}>
   Help
 </p>
</footer>
</div>
);
};
export default LoginPage;
