import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './DriverNotifications.module.css';

const DriversNotifications = ({
  initialDelay = 20000, // Prima notificare la 20 de secunde
  intervalDelay = 3 * 30 * 24 * 60 * 60 * 1000, // Odată la 3 luni
  onReload,
}) => {
  const [notifications, setNotifications] = useState([]);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [notificationIndex, setNotificationIndex] = useState(0);

  const fetchDriverData = async () => {
    try {
      const token = sessionStorage.getItem('token') || localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios.get('https://api-backend.full-trucking-service.com/api/drivers', config);
      const drivers = response.data;

      const today = new Date();
      const newNotifications = [];

      drivers.forEach((driver) => {
        const events = [
          {
            name: 'License Expires',
            date: driver.licenseExpiryDate,
            thresholds: [90, 30, 7, 1, 0],
          },
          {
            name: 'Passport Expires',
            date: driver.passportExpiryDate,
            thresholds: [90, 30, 7, 1, 0],
          },
          {
            name: 'Next Medical',
            date: driver.nextMedical,
            thresholds: [90, 30, 7, 1, 0],
          },
          {
            name: 'Last Drug Test',
            date: driver.lastDrugTest,
            thresholds: [-90, -30, -7, -1, 0],
          },
          {
            name: 'Last Alcohol Test',
            date: driver.lastAlcoholTest,
            thresholds: [-90, -30, -7, -1, 0],
          },
        ];

        events.forEach(({ name, date, thresholds }) => {
          if (date) {
            const eventDate = new Date(date);
            const daysLeft = Math.ceil((eventDate - today) / (1000 * 60 * 60 * 24));

            thresholds.forEach((threshold) => {
              if (daysLeft === threshold) {
                const message =
                  daysLeft > 0
                    ? `${name} for ${driver.firstName} ${driver.lastName} expires in ${daysLeft} days.`
                    : `${name} for ${driver.firstName} ${driver.lastName} occurred ${Math.abs(daysLeft)} days ago.`;
                newNotifications.push(message);
              }
            });
          }
        });
      });

      setNotifications(newNotifications);
    } catch (error) {
      console.error('Error fetching driver data:', error);
    }
  };

  useEffect(() => {
    fetchDriverData();
  }, []);

  useEffect(() => {
    if (notifications.length === 0) return;

    const showNextNotification = () => {
      setCurrentNotification(notifications[notificationIndex]);
      setNotificationIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    };

    const initialTimer = setTimeout(() => {
      showNextNotification();

      const intervalTimer = setInterval(() => {
        fetchDriverData();
        showNextNotification();
      }, intervalDelay);

      return () => clearInterval(intervalTimer);
    }, initialDelay);

    return () => clearTimeout(initialTimer);
  }, [notifications, notificationIndex, intervalDelay, initialDelay]);

  if (!currentNotification) return null;

  return (
    <div className={styles.notificationContainer}>
      <div className={styles.notification}>
        <p>{currentNotification}</p>
        <button
          className={styles.closeButton}
          onClick={() => setCurrentNotification(null)}
        >
          ×
        </button>
      </div>
    </div>
  );
};

export default DriversNotifications;
