import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ allowedRoles }) => {
  const token = localStorage.getItem('token');
  const tokenExpiration = localStorage.getItem('tokenExpiration');

  const isTokenValid = token && tokenExpiration && new Date() < new Date(tokenExpiration);

  if (!isTokenValid) {
    return <Navigate to="/login" />;
  }

  // Dacă utilizatorul este autentificat, permite accesul la ruta
  return <Outlet />;
};

export default PrivateRoute;
