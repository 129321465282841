import React, { useState, useEffect } from 'react';
import Notifications from './NotificationsSettings';

const TwoFactorNotification = ({ hasTwoFactorAuth, onNavigateToSection }) => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    // Afișează notificarea doar dacă 2FA este dezactivat
    if (!hasTwoFactorAuth) {
      setShowNotification(true);

      const hideTimeout = setTimeout(() => {
        setShowNotification(false); // Ascunde notificarea după 5 secunde
      }, 5000);

      return () => clearTimeout(hideTimeout); // Curăță timeout-ul la demontare
    }
  }, [hasTwoFactorAuth]);

  if (!showNotification) return null;

  const handleNotificationClick = () => {
    setShowNotification(false);
    onNavigateToSection('settings'); // Navighează către secțiunea "Settings"
  };

  return (
    <Notifications
      show={showNotification}
      message="Please set up Two-Factor Authentication to enhance your account security!"
      onClick={handleNotificationClick}
    />
  );
};

export default TwoFactorNotification;
