import { useState, useRef, useEffect } from "react";

const ChartMenu = ({ chartKey, chartTitle, chartTypes, handleChartTypeChange, downloadChartAsPDF }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  // Închidere meniu când utilizatorul face click în afara acestuia
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="chartMenu" ref={menuRef}>
      <button
        className="menuButton"
        onClick={(e) => {
          e.stopPropagation();
          setMenuOpen((prev) => !prev);
        }}
      >
        ⋮
      </button>

      {menuOpen && (
        <div className="menuDropdown">
          <label>Change Chart Type:</label>
          <select
            value={chartTypes[chartKey]}
            onChange={(e) => {
              handleChartTypeChange(chartKey, e.target.value);
              setMenuOpen(false);
            }}
          >
            <option value="Bar">Bar Chart</option>
            <option value="Column">Column Chart</option>
            <option value="Line">Line Graph</option>
            <option value="Doughnut">Doughnut Chart</option>
            <option value="Pie">Pie Chart</option>
            <option value="Gauge">Gauge Chart</option>
          </select>

          <button
            onClick={() => {
              downloadChartAsPDF(chartKey, chartTitle);
              setMenuOpen(false);
            }}
            className="downloadButton"
          >
            Download PDF
          </button>
        </div>
      )}
    </div>
  );
};

export default ChartMenu;
