import React from 'react';
import styles from '../styles/ContactPage.module.css';

const ContactPage = () => {
  return (
    <div className={styles.contactPageContainer}>
      <header className={styles.contactPageHeaderMenu}>
        <div className={styles.contactPagelogo}>
          Full Trucking Service
        </div>
        <nav>
          <ul>
            <li onClick={() => (window.location.href = '/about')}>About Us</li>
            <li onClick={() => (window.location.href = '/services')}>Services</li>
            <li onClick={() => (window.location.href = '/contact')}>Contact</li>
          </ul>
        </nav>
      </header>

      <div className={styles.contactPageContent}>
        <div className={styles.contactFormContainer}>
          <h1>Contact Us</h1>
          <p>
            Have questions? Need assistance? We’re here to help. Fill out the form below, and we’ll get back to you shortly.
          </p>

          <form className={styles.contactForm}>
            <input
              type="text"
              placeholder="Company Name"
              className={styles.contactInput}
              required
            />
            <input
              type="text"
              placeholder="Your Name"
              className={styles.contactInput}
              required
            />
            <input
              type="tel"
              placeholder="Phone Number"
              className={styles.contactInput}
              required
            />
            <input
              type="email"
              placeholder="Your Email"
              className={styles.contactInput}
              required
            />
            <textarea
              placeholder="Your Message"
              className={styles.contactTextarea}
              required
            ></textarea>
            <button type="submit" className={styles.contactButton}>
              Send Message
            </button>
          </form>
        </div>
        <div className={styles.contactDetails}>
          <h2>Contact Details</h2>
          <p>
            <strong>Email:</strong> support@fulltruckingservice.com
          </p>
          <p>
            <strong>Phone:</strong> +1 123 456 7890
          </p>
          <p>
            <strong>Address:</strong> 123 Trucking Lane, Logistics City, USA
          </p>
        </div>
      </div>

      <footer className={styles.contactPageFooter}>
        <p onClick={() => (window.location.href = '/terms')} className={styles.footerLink}>
          Terms of Service
        </p>
        <p onClick={() => (window.location.href = '/privacy')} className={styles.footerLink}>
          Privacy Policy
        </p>
      </footer>
    </div>
  );
};

export default ContactPage;
