import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './LoadsNotifications.module.css';
import { useNavigate } from 'react-router-dom';

const LoadsNotifications = ({
  initialDelay = 10000, // Prima notificare după 10 secunde
  intervalDelay = 30 * 60 * 1000, // La fiecare 30 minute
  hideAfter = 5000, // Notificarea dispare după 5 secunde
  resetDelay = 24 * 60 * 60 * 1000, // Resetare după 24 de ore
  onNavigateToSection,
}) => {
  const [incompleteLoads, setIncompleteLoads] = useState([]);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchIncompleteLoads = async () => {
      try {
        const token = sessionStorage.getItem('token') || localStorage.getItem('token');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };

        const response = await axios.get('https://api-backend.full-trucking-service.com/api/loads/incomplete', config);
        setIncompleteLoads(response.data);
      } catch (error) {
        console.error('Error fetching incomplete loads:', error);
      }
    };

    fetchIncompleteLoads();
  }, []);

  useEffect(() => {
    if (incompleteLoads.length === 0) return;

    const showNextNotification = () => {
      const lastDismissed = localStorage.getItem('lastDismissedNotification');
      const currentTime = new Date().getTime();

      if (lastDismissed && currentTime - lastDismissed < resetDelay) {
        console.log('Notificarea a fost deja afișată în ultimele 24 de ore.');
        return;
      }

      setCurrentNotification(incompleteLoads[0]);
      setNotificationVisible(true);

      setTimeout(() => {
        setNotificationVisible(false);
        localStorage.setItem('lastDismissedNotification', currentTime.toString());
      }, hideAfter);
    };

    const initialTimer = setTimeout(() => {
      showNextNotification();
      const intervalTimer = setInterval(() => {
        showNextNotification();
      }, intervalDelay);

      return () => clearInterval(intervalTimer);
    }, initialDelay);

    return () => clearTimeout(initialTimer);
  }, [incompleteLoads, initialDelay, intervalDelay, hideAfter, resetDelay]);

  const handleCloseNotification = () => {
    setNotificationVisible(false);
    const currentTime = new Date().getTime();
    localStorage.setItem('lastDismissedNotification', currentTime.toString());
  };

  const handleNotificationClick = (loadId) => {
    onNavigateToSection('load-manager', loadId);
    handleCloseNotification();
  };

  if (!notificationVisible || !currentNotification) return null;

  return (
    <div className={styles.notificationContainer}>
      <div
        className={styles.notification}
        onClick={() => handleNotificationClick(currentNotification._id)}
      >
        Load {currentNotification.loadNumber} is still {currentNotification.status} in{' '}
        {currentNotification.shippers?.[0]?.name || 'Unknown Shipper'}.
        <button
          className={styles.closeButton}
          onClick={(e) => {
            e.stopPropagation();
            handleCloseNotification();
          }}
        >
          ×
        </button>
      </div>
    </div>
  );
};

export default LoadsNotifications;
