import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/AboutUsPage.module.css';
import videoBackground from '../styles/background.mp4';

const AboutUsPage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.aboutUsPageContainer}>
      <video className={styles.aboutUsVideoBackground} autoPlay loop muted>
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <header className={styles.aboutUsHeaderMenu}>
        <div onClick={() => navigate('/')} className={styles.aboutUsLogo}>
          Full Trucking Service
        </div>
        <nav>
          <ul>
            <li onClick={() => navigate('/about')}>About Us</li>
            <li onClick={() => navigate('/services')}>Services</li>
            <li onClick={() => navigate('/contact')}>Contact</li>
            <li onClick={() => navigate('/register')}>Register</li>
          </ul>
        </nav>
      </header>

      <div className={styles.aboutUsMainContent}>
        <div className={styles.textSection}>
          <h1>About Us - Full Trucking Service</h1>
          <p>
            At Full Trucking Service, we are dedicated to revolutionizing the trucking and logistics industry through
            innovative solutions tailored to the unique needs of transportation companies across North and South
            America. Our platform is designed to simplify the most challenging aspects of trucking, providing a seamless,
            efficient, and user-friendly application for dispatching and accounting. Whether you're a small operation or
            a large-scale fleet, our services are crafted to help you thrive in this competitive industry.
          </p>
          <h2>Our Mission</h2>
          <p>
            Our mission is to empower trucking companies by automating and streamlining critical operational processes.
            We understand the immense challenges that come with running a trucking business—tight schedules, compliance
            requirements, and the constant pressure to optimize performance. That’s why Full Trucking Service is more
            than just a tool; it’s a partner in your success, reducing stress and freeing up time so you can focus on
            growing your business.
          </p>
          <h2>Our Story</h2>
          <p>
            We didn’t just stumble upon this industry—we are part of it. Our team is composed of professionals with
            decades of experience in transportation, including former drivers, dispatchers, accountants, and even
            executives from some of the largest trucking companies. This hands-on experience has given us a deep
            understanding of the pain points faced by trucking companies, and we have channeled that knowledge into
            building a solution that truly makes a difference.
          </p>
          <h2>What We Offer</h2>
          <ul>
            <li>Comprehensive Accounting Tools</li>
            <li>Efficient Dispatch Management</li>
            <li>Driver and Fleet Management</li>
            <li>Data-Driven Insights</li>
            <li>User-Friendly Interface</li>
          </ul>
          <h2>Why Choose Us?</h2>
          <p>
            Experience That Matters: We are not just software developers; we are trucking professionals who know the
            industry inside out. Our team has walked in your shoes and understands the unique challenges you face.
          </p>
          <h2>Our Commitment to Excellence</h2>
          <p>
            At Full Trucking Service, we believe that trucking companies deserve better tools to manage their operations.
            We are committed to continuous improvement, listening to our clients, and adapting our platform to the
            ever-changing demands of the industry. Our goal is to not only meet your expectations but to exceed them,
            helping your business reach new heights.
          </p>
          <h2>The Future of Trucking</h2>
          <p>
            As the transportation industry evolves, so do we. Full Trucking Service is at the forefront of innovation,
            integrating the latest technologies to ensure that your company stays ahead of the curve. From real-time
            tracking and cloud-based solutions to compliance management and beyond, we are dedicated to providing you
            with the tools you need to succeed in an increasingly competitive market.
          </p>
          <h2>Join the Full Trucking Service Community</h2>
          <p>
            When you choose Full Trucking Service, you’re not just getting an application; you’re joining a community of
            trucking professionals who are passionate about making the industry better. Together, we can transform the
            way trucking companies operate, making the industry more efficient, sustainable, and profitable for everyone
            involved.
          </p>
        </div>
      </div>

      <footer className={styles.aboutUsFooter}>
        <p onClick={() => navigate('/terms')} className={styles.footerLink}>
          Terms of Service
        </p>
        <p onClick={() => navigate('/privacy')} className={styles.footerLink}>
          Privacy Policy
        </p>
      </footer>
    </div>
  );
};

export default AboutUsPage;
