import React from 'react';
import styles from './Loader.module.css'; // Stilurile sunt separate

const Loader = ({ isLoading, text = 'Loading...' }) => {
  if (!isLoading) return null; // Dacă nu este loading, nu afișa nimic

  return (
    <div className={styles.loadingOverlay}>
      <div className={styles.loadingContainer}>
        <img
          src="/assets/truck.png"
          alt="Loading..."
          className={styles.loadingTruck}
        />
        <p className={styles.loadingText}>{text}</p>
      </div>
    </div>
  );
};

export default Loader;
